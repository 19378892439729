export default defineAppConfig({
  netzo: {
    title: 'ESGRAF',
    description: 'Software de gestión de empresas (ERP) de ESGRAF.',
    auth: {
      oauth: ['google'],
    },
  },
  ui: {
    primary: 'blue',
    gray: 'neutral',
  },
})
